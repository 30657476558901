import { useEffect } from "react";
import { Link } from "react-router-dom";

import '../styles/home.css'

import Arrow from '../assets/arrow_svg.svg'

const Home = (props) => {
    useEffect(() =>{
        props.setStep(1);
    })

    return (
        <section className="landing-page">
            <div className="header">
                <div className="header__title">
                    <h1>BEZPLATNÉ A NEZÁVAZNÉ POSOUZENÍ VAŠÍ POHLEDÁVKY</h1>
                </div>
                <div className="header__subtitle header__subtitle--box">
                    <h2>Vymůžeme až 95% předaných pohledávek.<br /><br /> Máme skvělé reference.</h2>
                </div>
                <p className="header__text">
                    Množí se Vám neuhrazené faktury? <br />
                    Nedaří se Vám dostat z dlužníka peníze za půjčku, směnku či nájemné? <br />
                    Nevyplatil Vám zaměstnavatel dlužnou mzdu? 
                </p>

                <b className="header__arrow">
                    Poradíme Vám!
                </b>
            </div>

            <div className="type-first">
                <div className="type-first__buttons">
                <Link to='/krok1'>
                    <div className="type-first__button button flex">
                        <p>CHCI BEZPLATNĚ <br/> PORADIT</p>
                        <img className="button__arrow" src={ Arrow } />
                    </div>
                </Link>
                </div>
            </div>
        </section>
    )
}

export default Home;