import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import './styles/main.css'

import Home from './components/Home'
import Lead from './components/Lead'
import Receivable from './components/Receivable'
import Personal from './components/Personal'
import TryUs from './components/TryUs'
import Navigation from './components/partials/Navigation'
import Footer from './components/partials/Footer'
import Wolves from './components/partials/Wolves'
import { useState } from 'react'

const App = () => {
  const [step, setStep] = useState(1)
  const [data, setData] = useState('')

  const validateEmail = (email) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return true
    }
    
    return false
  }

  const throwMsg = (message, element, duration) => {
    element.style.display = "block"
    element.innerHTML = message
    setTimeout(() => {
        element.style.opacity = 1
    }, 100)
    setTimeout(() => {
        element.style.opacity = 0
        element.style.display = "none"
    }, duration)
  }

  return (
    <div className="App">
      <Navigation />
      <TryUs />
      <div className='finio-app'>
        <Router>            
            <Switch>
                <Route path="/" exact render={ () => <Home setStep={ setStep } /> } />
                <Route path="/krok1" exact render={ () =>
                  <Lead setStep={ setStep }
                    data = { data }
                    setData={ setData }
                    validateEmail={ validateEmail }
                    throwMsg={ throwMsg } />
                  } />
                <Route path="/krok2" exact render={ () =>
                  <Receivable setStep={ setStep }
                    data = { data }
                    setData={ setData } 
                    validateEmail={ validateEmail }
                    throwMsg={ throwMsg } />
                  } />
                <Route path="/krok3" exact render={ () =>
                  <Personal setStep={ setStep }
                    setData={ setData }
                    data={ data }
                    throwMsg={ throwMsg } />
                  } />
            </Switch>
        </Router>
      </div>
      <Wolves step={ step } />
      <Footer />
    </div>
  );
}

export default App;
