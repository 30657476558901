import { useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { $ }  from 'react-jquery-plugin'

import '../styles/lead.css'
import Arrow from '../assets/arrow_svg.svg'

const Lead = (props) => {
    useEffect(() =>{
        props.setStep(2);

        // set input values to data object if already exists
        if (props.data) {
            emailRef.current.value = props.data.email
            phoneRef.current.value = props.data.phone
            receivableRef.current.value = props.data.costLead
            couponRef.current.name = props.data.coupon
        }
    }, [])

    const history = useHistory()

    const emailRef = useRef()
    const phoneRef = useRef()
    const checkRef = useRef()
    const receivableRef = useRef()
    const couponRef = useRef()

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        
        if (emailRef.current.value !== '' || phoneRef.current.value !== '') {
            if (emailRef.current.value !== '' && !props.validateEmail(emailRef.current.value)) {
                props.throwMsg('E-mail není vyplněn správně.', document.querySelector('#lead-err'), 3000)
            } else if(!checkRef.current.checked) {
                props.throwMsg('Pro pokračování musíte souhlasit se zpracováním osobních údajů.', document.querySelector('#lead-err'), 3000)
            } else {
                history.push('/krok2')
                
                props.setData(
                    {
                        email: emailRef.current.value,
                        phone: phoneRef.current.value,
                        costLead: receivableRef.current.value,
                        coupon: couponRef.current.value,
                        receivables: props.data.receivables ? props.data.receivables : [] // set to itself if exists, otherway set to an empty array
                    }
                )
                
                $.ajax({
                    url: "https://ipohledavky.finio.cz/auto_mail.php",
                    data:'phone=' + phoneRef.current.value + '&email=' + emailRef.current.value,
                    type: "POST",
                    success:function(data){
                        console.log(data)
                    },
                    error:function (err){
                        console.log(err)
                    }
                })
            }
        } else {
            props.throwMsg('Vyplňte, prosím, alespoň jedno z polí.', document.querySelector('#lead-err'), 3000)
        } 

        return false
    }
    
    return (
        <section className="lead-form-page">
            <div className="header">
                <div className="header__title">
                    <h1>
                    KROK 1 - JAK VÁS MÁME KONTAKTOVAT <br />
                    S NÁVRHEM ŘEŠENÍ?
                    </h1>
                </div>
                <div className="header__subtitle header__subtitle--text">
                    
                </div>
            </div>
            <div className="contact">
                <div className="contact__error-message" id="lead-err"></div>
                
                <form className="contact__form lead__form" action="" method="POST">
                    <small className="lead-label">Vyplňte, prosím, alespoň jeden z kontaktních údajů.</small>
                    <input name="lead_tel" type="text" id="lead_tel" placeholder="Telefon"
                        ref={ phoneRef } />
                    <input name="email" type="email" id="mail" placeholder="Email"
                        ref={ emailRef } />
                    <hr />
                    <input name="lead_receivable" type="number" placeholder="Výše Vaší pohledávky"
                        ref={ receivableRef }
                         />
                    <small className="lead-label"
                        style={{ marginTop: 15 + 'px' }}>Zde uplatněte případný slevový kód.</small>
                    <input type="text"
                        required ref={ couponRef }
                        />
                    <div className="lead__check form-check">
                        <input type="checkbox" className="lead__check" ref={ checkRef } />
                        <label className="gdpr-line">
                            Souhlasím se <a href="https://www.finio.cz/zpracovani-osobnich-udaju" target="_blank" className="gdpr-link">zpracováním  osobních údajů</a>.
                        </label>
                    </div>
                </form>
                <div className="lead__link" id="lead-btn" style={{textAlign: 'center'}} onClick={ e => handleSubmit(e) }>
                        <div className="contact__fake-btn button flex" style={{display: 'inline-block'}}>
                            <p>DALŠÍ KROK</p>
                            <img className="button__arrow" src={ Arrow } />
                        </div>
                    </div>
            </div>
        </section>
    )
}

export default Lead;