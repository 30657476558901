import { useEffect, useRef, useState } from "react";
import { $ }  from 'react-jquery-plugin'
import { Link, useHistory } from "react-router-dom";

import '../styles/receivable.css'
import Arrow from '../assets/arrow_svg.svg'
import Info from '../assets/info.png'
import Close from '../assets/close.png'
import Add from '../assets/add.svg'

const Receivable = (props) => {
    const history = useHistory()

    useEffect(() =>{
        // if data are empty or null redirect to the home page
        if (props.data == '' || props.data === null) {
            history.push('/')
        } else {
            props.setStep(3);

            // preset cost value from previous step
            if (props.data.costLead) {
                foCostRef.current.value = props.data.costLead
            }

            //setCount(props.data.receivables.length)
        }
    }, [])

    const [selectedType, setSelectedType] = useState('fo')
    const [info, setInfo] = useState('') // Infoboxes' value (infobox is not rendered if this state is empty)
    const [count, setCount] = useState(props.data.receivables ? props.data.receivables.length : 0)

    // FO references
    const foNameRef = useRef()
    const foIdTypeRef = useRef()
    const foIdRef = useRef()
    const foAdressRef = useRef()
    const foEmailRef = useRef()
    const foPhoneRef = useRef()
    const foReasonRef = useRef()
    const foCostRef = useRef()
    const foDateRef = useRef()
    const foMsgRef = useRef()

    // PO references
    const poCompanyRef = useRef()
    const poIdRef = useRef()
    const poAdressRef = useRef()
    const poEmailRef = useRef()
    const poPhoneRef = useRef()
    const poReasonRef = useRef()
    const poCostRef = useRef()
    const poDateRef = useRef()
    const poMsgRef = useRef()

    const submitForm = () => {
        // route to next step
        history.push('/krok3')

        /*
        NOTFIKACE PO ODESLÁNÍ POHLEDÁVKY ZRUŠENA!

        // TODO: 
        const stringData = JSON.stringify(props.data)
        // console.log('pohledavka string data test: ')
        // console.log(stringData)
        $.ajax({
            url: "https://ipohledavky.finio.cz/receivable.php",
            data: stringData,
            type: "POST",
            success:function(data){
                console.log(data)
            },
            error:function (err){
                console.log(err)
            }
        });
        */
    }

    const handleSubmit = () => {
        // if array is empty try to add new and the submit
        if (props.data.receivables.length < 1) {
            if (handleAddNew() === true) {
                submitForm()
            }
        } else {
            // if user filled atleast one field and tried to submit without adding new item 
            if (selectedType === 'fo') {
                if (foNameRef.current.value !== ''
                || foIdRef.current.value !== '' || foAdressRef.current.value !== ''
                || foEmailRef.current.value !== '' || foEmailRef.current.value !== ''
                || foPhoneRef.current.value !== '' || foReasonRef.current.value !== ''
                || foCostRef.current.value !== '' || foDateRef.current.value !== '') {
                    if (handleAddNew() === true) {
                        submitForm()
                    }
                } else {
                    submitForm()
                }
            } else if (selectedType === 'po') {
                if (poCompanyRef.current.value !== ''
                || poIdRef.current.value !== '' || poAdressRef.current.value !== ''
                || poEmailRef.current.value !== '' || poEmailRef.current.value !== ''
                || poPhoneRef.current.value !== '' || poReasonRef.current.value !== ''
                || poCostRef.current.value !== '' || poDateRef.current.value !== '') {
                    if (handleAddNew() === true) {
                        submitForm()
                    }
                } else {
                    submitForm()
                }
            }
        }
    }

    const handleAddNew = () => {
        const foForm = document.querySelector('.fo-form')
        const poForm = document.querySelector('.po-form')

        // If receivable is FO
        if (selectedType === 'fo') {
            if (foNameRef.current.value !== '' && foIdTypeRef.current.value !== ''
            && foIdRef.current.value !== '' && foAdressRef.current.value !== ''
            && foEmailRef.current.value !== '' && foEmailRef.current.value !== ''
            && foPhoneRef.current.value !== '' && foReasonRef.current.value !== ''
            && foCostRef.current.value !== '' && foDateRef.current.value !== '') {
                if (!props.validateEmail(foEmailRef.current.value)) {
                    props.throwMsg('E-mail není vyplněn správně.', document.querySelector('.pohledavky__error-message'), 3000)
                } else {
                    props.data.receivables.push({
                        type: selectedType,
                        name: foNameRef.current.value,
                        idType: foIdTypeRef.current.value,
                        id: foIdRef.current.value,
                        adress: foAdressRef.current.value,
                        email: foEmailRef.current.value,
                        phone: foPhoneRef.current.value,
                        reason: foReasonRef.current.value,
                        cost: foCostRef.current.value,
                        date: foDateRef.current.value,
                        message: foMsgRef.current.value
                    })

                    setCount(count + 1)
                    props.throwMsg('Pohledávka byla úspěšně uložena!', document.querySelector('.pohledavky__success-message'), 3000)
                    foForm.reset() // clear form after successful submition
                    return true
                }
            } else {
                props.throwMsg("Vyplňte, prosím, všechna povinná pole.", document.querySelector('.pohledavky__error-message'), 3000)
            }
        } else if (selectedType === 'po') {
            if (poCompanyRef.current.value !== ''
            && poIdRef.current.value !== '' && poAdressRef.current.value !== ''
            && poEmailRef.current.value !== '' && poEmailRef.current.value !== ''
            && poPhoneRef.current.value !== '' && poReasonRef.current.value !== ''
            && poCostRef.current.value !== '' && poDateRef.current.value !== '') {
                if (!props.validateEmail(poEmailRef.current.value)) {
                    props.throwMsg('E-mail není vyplněn správně.', document.querySelector('.pohledavky__error-message'), 3000)
                } else {
                    props.data.receivables.push({
                        type: selectedType,
                        company: poCompanyRef.current.value,
                        id: poIdRef.current.value,
                        adress: poAdressRef.current.value,
                        email: poEmailRef.current.value,
                        phone: poPhoneRef.current.value,
                        reason: poReasonRef.current.value,
                        cost: poCostRef.current.value,
                        date: poDateRef.current.value,
                        message: poMsgRef.current.value
                    })

                    setCount(count + 1)
                    props.throwMsg('Pohledávka byla úspěšně uložena!', document.querySelector('.pohledavky__success-message'), 3000)
                    poForm.reset() // clear form after successful submition
                    return true
                } 
            } else {
                props.throwMsg("Vyplňte, prosím, všechna povinná pole.", document.querySelector('.pohledavky__error-message'), 3000)
            }
        }
        return false
    }

    // check if browser supports input type date
    const hasDatePicker = () => {
        var input = document.createElement('input');
        input.setAttribute('type','date');
    
        var notADateValue = 'not-a-date';
        input.setAttribute('value', notADateValue); 
    
        return (input.value !== notADateValue);
    }

    const checkDate = (e) => {
        if (hasDatePicker()) {
            let todayDate = new Date();
            let oldDate = new Date(e.target.value);
            
            if ((todayDate - oldDate) / (1000 * 3600 * 24*365) > 3) {
                props.throwMsg('Pokud nedisponujete dlužníkem podepsaným Uznáním dluhu co do důvodu a výše, je pohledávka <b>3 roky</b> po datu splatnosti promlčena.', document.querySelector('.pohledavky__error-message'), 7000)
            }
        }
    }

    return (
        <section className="pohledavky-page">
            <div className="header">
                <div className="header__title">
                    <h1>KROK 2 - INFORMACE O POHLEDÁVCE</h1>
                </div>
            </div>
            <div className="pohledavky__error-message"></div>
            <div className="pohledavky__success-message"></div>
            {info !== '' ? 
                <div className="pohledavky__info">
                    <img src={ Close } alt="close"
                        onClick={ () => setInfo('') }/>
                    <p>{ info }</p>
                </div>
            : ''}
            <div className="pohledavky">
                <div className="pohledavky__inner">
                    { selectedType === 'fo' ? 
                    <div className="pohledavky__col">
                        <form className="fo-form">
                            <div className="input-wrapper">
                                <select className="input-full" onChange={ (e) => setSelectedType(e.target.value) }>
                                    <option value="fo">Dlužník - fyzická osoba</option>
                                    <option value="po">Dlužník - právnická osoba</option>
                                </select>
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="text"
                                    ref={ foNameRef } 
                                    placeholder="*Jméno a příjmení dlužníka" />
                            </div>
                            <div className="input-wrapper">
                                <select className="select-part" ref={ foIdTypeRef }>
                                    <option value="datum_narozeni">Datum narození dlužníka</option>
                                    <option value="rodne_cislo">Rodné číslo dlužníka</option>
                                    <option value="ic">IČ dlužníka</option>
                                </select>
                                <input className="input-part" type="text"
                                    ref={ foIdRef }
                                    placeholder="*" />
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="text"
                                    ref={ foAdressRef }
                                    placeholder="*Adresa bydliště dlužníka" />
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="email"
                                    ref={ foEmailRef }
                                    placeholder="*E-mail na dlužníka" />
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="text"
                                    ref={ foPhoneRef }
                                    placeholder="*Telefon na dlužníka" />
                            </div>
                            <div className="input-wrapper">
                                <img src={ Info } alt="info"
                                    onClick={ () => setInfo('Např. faktura po splatnosti, nezaplacená směnka, smluvní pokuta, nezaplacená mzda apod.') } />
                                <input className="input-full" type="text"
                                    ref={ foReasonRef }
                                    placeholder="*Právní důvod pohledávky" />
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="number"
                                    ref={ foCostRef }
                                    placeholder="*Částka" />
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="text"
                                    ref={ foDateRef }
                                    placeholder="*Datum splatnosti"
                                    data-date-format="dd.mm.yyyy"
                                    onFocus={ e => e.target.setAttribute('type', 'date') }
                                    onBlur={ e => e.target.setAttribute('type', 'text') }
                                    onChange={ e => checkDate(e) } />
                            </div>
                            <div className="input-wrapper">
                                <textarea className="textarea-full" ref={ foMsgRef }
                                    placeholder="Další informace o pohledávce" rows="3" />
                            </div>
                        </form>
                    </div>
                    :
                    <div className="pohledavky__col">
                        <form className="po-form">
                            <div className="input-wrapper">
                                <select className="input-full" onChange={ (e) => setSelectedType(e.target.value) }>
                                    <option value="fo">Dlužník - fyzická osoba</option>
                                    <option value="po">Dlužník - právnická osoba</option>
                                </select>
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="text"
                                    ref={ poCompanyRef }
                                    placeholder="*Název společnosti (dlužníka)" />
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="text"
                                    ref={ poIdRef }
                                    placeholder="*IČO dlužníka" />
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="text"
                                    ref={ poAdressRef }
                                    placeholder="*Adresa sídla dlužníka" />
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="email"
                                    ref={ poEmailRef }
                                    placeholder="*E-mail na dlužníka" />
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="text"
                                    ref={ poPhoneRef }
                                    placeholder="*Telefon na dlužníka" />
                            </div>
                            <div className="input-wrapper">
                                <img src={ Info } alt="info"
                                    onClick={ () => setInfo('Např. faktura po splatnosti, nezaplacená směnka, smluvní pokuta, nezaplacená mzda apod.') } />
                                <input className="input-full" type="text"
                                    ref={ poReasonRef }
                                    placeholder="*Právní důvod pohledávky" />
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="number"
                                    ref={ poCostRef }
                                    placeholder="*Částka" />
                            </div>
                            <div className="input-wrapper">
                                <input className="input-full" type="text"
                                    ref={ poDateRef }
                                    placeholder="*Datum splatnosti"
                                    data-date-format="dd.mm.yyyy"
                                    onFocus={ e => e.target.setAttribute('type', 'date') }
                                    onBlur={ e => e.target.setAttribute('type', 'text') }
                                    onChange={ e => checkDate(e) } />
                            </div>
                            <div className="input-wrapper">
                                <textarea className="textarea-full"
                                    ref={ poMsgRef }
                                    placeholder="Další informace o pohledávce" rows="3" />
                            </div>
                        </form>
                    </div>
                    }
                    
                </div>
                <div className="pohledavky__controller">
                    <p className="pohledavky__count">Počet předaných pohledávek: <b>{ count }</b></p>
                    {
                        /*
                        <div className="pohledavky__add-new"
                            onClick={ handleAddNew }>
                            <p>PŘIDAT POHLEDÁVKU</p>
                            <button className="button button--inline">
                                <img className="button__add" src={ Add } alt="přidat" />
                            </button>
                        </div>
                        */
                    }
                </div>
            </div>

            <div className="button-container">    
                <div className="pohledavky__send" onClick={ handleSubmit }>
                    <button id="pohledavky-next-step" className="button">
                        <p>POKRAČOVAT NA POSLEDNÍ KROK</p>
                        <img className="button__arrow" src={ Arrow } alt="šipka" />
                    </button>
                </div>
                <div className="button-container__dots"></div>
                <div className="pohledavky__send" onClick={ handleAddNew }>
                    <button id="pohledavky-add-more" className="button">
                        <p>VLOŽIT DALŠÍ POHLEDÁVKU</p>
                        <img className="button__arrow" src={ Arrow } alt="šipka" />
                    </button>
                </div>
            </div>

        </section>
    )
}

export default Receivable;