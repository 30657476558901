import Wolf from '../../assets/wolf.png'
import Bubble from '../../assets/bubble.svg'
import { useState } from 'react'

const Wolves = (props) => {
    const [texts, setTexts] = useState([
        {
            first: 'Sdělte nám informace o Vaší pohledávce online ve 3 jednoduchých krocích',
            second: 'Vaši pohledávku bezplatně posoudíme a navrhneme Vám další postup'
        },
        {
            first: 'Bezplatně Vám poradíme!',
            second: 'Zanechte nám na sebe spojení a my se Vám ozveme zpět!'
        },
        {
            first: 'Právním důvodem pohledávky může být faktura, smlouva, směnka atd.',
            second: 'Prozraďte nám co nejvíce informací o Vaší pohledávce.'
        },
        {
            first: 'Připravíme pro Vás návrh řešení a Vy se pak můžete rozhodnout, zda nám pohledávku předáte k vymáhání!',
            second: 'Nabízíme Vám možnost komplexního vymáhání Vaší pohledávky, které pro Vás také může být ZDARMA!'
        }    
    ])

    const [size, setSize] = useState([
        {
            first: {
                fontSize: 1.4 + 'em',
                padding: '50px 20px 20px 40px'
            },
            second: {
                fontSize: 1.4 + 'em',
                padding: '55px 40px 20px 20px'
            }
        },
        {
            first: {
                fontSize: 2 + 'em',
                padding: '60px 20px 20px 40px'
            },
            second: {
                fontSize: 1.4 + 'em',
                padding: '55px 40px 20px 20px'
            }
        },
        {
            first: {
                fontSize: 1.33 + 'em',
                padding: '55px 20px 20px 40px'
            },
            second: {
                fontSize: 1.4 + 'em',
                padding: '60px 40px 20px 20px'
            }
        },
        {
            first: {
                fontSize: 1.175 + 'em',
                padding: '57.5px 20px 20px 40px'
            },
            second: {
                fontSize: 1.225 + 'em',
                padding: '50px 40px 20px 20px'
            }
        }
    ])

    return(
        <div className="speech-bubbles">
            <div className="speech-bubbles_element speech-bubbles_element--left">
                <img src={ Wolf } alt="wolf" />
                <div className="bubble" style={{backgroundImage: 'url(' + Bubble + ')'}}>
                    <p style={ size[props.step - 1].first }>{ texts[props.step - 1].first }</p>
                </div>
            </div>
            <div className="speech-bubbles_element speech-bubbles_element--right">
                <img src={ Wolf } alt="wolf" />
                <div className="bubble" style={{backgroundImage: 'url(' + Bubble + ')'}}>
                    <p style={ size[props.step - 1].second }>{ texts[props.step - 1].second }</p>
                </div>
            </div>
        </div>
    )
}

export default Wolves