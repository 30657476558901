import { useEffect, useRef } from "react";
import { $ }  from 'react-jquery-plugin'

import '../styles/personal.css'
import Arrow from '../assets/arrow_svg.svg'
import { useHistory } from "react-router";

const Personal = (props) => {
    const history = useHistory()

    useEffect(() =>{
        // if data are empty or null redirect to the home page
        if (props.data == '' || props.data === null) {
            history.push('/')
        } else {
            props.setStep(4);
            console.log(props.data)
            // more or less useless
            if (props.data.customerName) {
                nameRef.current.value = props.data.customerName
                newsCheckRef.current.checked = props.data.newsAgreed
            }
        }
    }, [])

    const nameRef = useRef()
    const newsCheckRef = useRef()
    // const couponRef = useRef()

    const handleSubmit = () => {
        if (nameRef.current.value === '') {
            props.throwMsg('Vyplňte, prosím, jméno nebo název společnosti.', document.querySelector('.contact__error-message'), 3000)
        } else {
            props.setData({
                ...props.data,
                customerName: nameRef.current.value,
                newsAgreed: newsCheckRef.current.checked
            })

            const stringData = JSON.stringify({
                ...props.data,
                customerName: nameRef.current.value,
                newsAgreed: newsCheckRef.current.checked
            })
            console.log(stringData)

            $.ajax({
                url: "https://ipohledavky.finio.cz/submit.php",
                data: stringData,
                type: "POST",
                success:function(data){
                    console.log(data)
                },
                error:function (err){
                    console.log(err)
                }
            });

            props.throwMsg('Formulář byl úspěšně odeslán. Děkujeme za Váš čas!', document.querySelector('.contact__success-message'), 3000)

            setTimeout(() => {
                history.push('/')
            }, 3750);

        }
    }

    return (
        <section className="form-page">
            <div className="header">
                <div className="header__title">
                    <h1>KROK 3 - INFORMACE O VAŠÍ OSOBĚ</h1>
                </div>
            </div>
            <div className="contact">
                <div className="contact__error-message"></div>
                <div className="contact__success-message" id="lead-suc"></div>

                <form className="contact__form" action="" method="POST">
                    <input type="text" placeholder="*Jméno a příjmení / Název společnosti"
                    required ref={ nameRef } />
                    <div className="contact__check form-check">
                        <input type="checkbox" className="lead__check" ref={ newsCheckRef } />
                        <label className="gdpr-line">
                            Chci zasílat informace o novinkách na inkasním trhu.
                        </label>
                    </div>
                    <div className="lead__link">
                        <div id="final-button" className="contact__fake-btn button flex"
                        onClick={ handleSubmit }>
                            <p>HOTOVO</p>
                            <img className="button__arrow" src={ Arrow } />
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Personal;