import React, { useRef, useState } from 'react'
import { $ }  from 'react-jquery-plugin'

const TryUs = () => {

    const nameRef = useRef('')
    const firmRef = useRef('')
    const emailRef = useRef('')
    const phoneRef = useRef('')
    const checkRef = useRef('')


    const handleCloseForm = () => {
        const formTry = document.querySelector('#form-try')
        formTry.style.display = 'none'
    }

    const handleSendForm = () => {
        const inputWrappers = document.querySelectorAll('.form-group');
        const modalErr = document.querySelector('.modal-warning');
        const modalSuccess = document.querySelector('.modal-success');

        inputWrappers.forEach(wrapper => {
            const input = wrapper.querySelector('input');
            if (input.value == '') {
                wrapper.classList.add('has-error');
            } else {
                wrapper.classList.remove('has-error');
            }
        });

        if (nameRef.current.value != '' && firmRef.current.value != '' && emailRef.current.value != '' && checkRef.current.checked == true) {
            console.log(nameRef.current.value + " " + firmRef.current.value + " " + emailRef.current.value + " " + checkRef.current.checked);

            $.ajax({
                url: 'https://ipohledavky.finio.cz/auto_form-try.php',
                data:'name-try=' + nameRef.current.value + '&firm-try=' + firmRef.current.value + '&email-try=' + emailRef.current.value + '&phone-try=' + phoneRef.current.value,
                type: "POST",
                success:function(data){
                    console.log(data);
                    handleCloseForm();

                    modalSuccess.style.display = 'flex';
                    setTimeout(() => {
                        modalSuccess.style.display = 'none';
                    }, 2500);
                },
                error:function (err){
                    console.log('form try err:' + err);
                }
            });

        } else {
            modalErr.style.display = 'block';
        }
    }

    return (
        <>
            <div className="modal-success">
                <p>Formulář byl úspěšně odeslán. Brzy se Vám ozveme.</p>
            </div>

            <div className="modal fade modal-xs in" tabIndex="-1" role="dialog" id="form-try" aria-hidden="true"
                style={{display: 'none', justifyContent: 'center', alignItems: 'center', zIndex: 99999999, width: 100 + 'vw', height: 100 + 'vh', background: 'rgba(0,0,0,0.5)'}}>
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" onClick={ handleCloseForm }>×</button>
                            <h2 style={{padding: '0 10px'}} className="modal-demand-title">Zanechte nám kontakt, ozveme se Vám zpět.</h2>
                        </div>
                        <div className="modal-body">
                            <div id="snippet-tryitForm-1-tryitForm">
                                <p className="modal-warning">Vyplňte všechna povinná pole.</p>
                                <form action="" method="post">
                                    <div className="form-group">
                                        <input type="text" name="name-try" id="frm-tryitForm-1-form-name" required="" ref={ nameRef }
                                            data-nette-rules="[{&quot;op&quot;:&quot;:filled&quot;,&quot;msg&quot;:&quot;Jméno a příjmení je povinná položka.&quot;}]"
                                            className="form-control has-js-label" data-lfv-initialized="true"
                                            data-lfv-message-id="frm-tryitForm-1-form-name_message"
                                            data-placeholder="Jméno a příjmení*" placeholder="" data-js-label="true" /><label
                                            className="js-label" htmlFor="frm-tryitForm-1-form-name">Jméno a příjmení*</label> <span
                                            id="frm-tryitForm-1-form-name_message"></span></div>

                                    <div className="form-group">
                                        <input type="text" name="firm-try" id="frm-tryitForm-1-form-firm" required="" ref={ firmRef }
                                            data-nette-rules="[{&quot;op&quot;:&quot;:filled&quot;,&quot;msg&quot;:&quot;Firma je povinná položka.&quot;}]"
                                            className="form-control has-js-label" data-lfv-initialized="true"
                                            data-lfv-message-id="frm-tryitForm-1-form-firm_message" data-placeholder="Firma*"
                                            placeholder="" data-js-label="true" /><label className="js-label"
                                            htmlFor="frm-tryitForm-1-form-firm">Firma*</label> <span
                                            id="frm-tryitForm-1-form-firm_message"></span></div>

                                    <div className="form-group">
                                        <input type="email" name="email-try" id="frm-tryitForm-1-form-email" required="" ref={ emailRef }
                                            data-nette-rules="[{&quot;op&quot;:&quot;:filled&quot;,&quot;msg&quot;:&quot;E-mail je povinná položka.&quot;},{&quot;op&quot;:&quot;:email&quot;,&quot;msg&quot;:&quot;Zadejte prosím platnou e-mailovou adresu.&quot;}]"
                                            className="form-control has-js-label" data-lfv-initialized="true"
                                            data-lfv-message-id="frm-tryitForm-1-form-email_message" data-placeholder="E-mail*"
                                            placeholder="" data-js-label="true" /><label className="js-label"
                                            htmlFor="frm-tryitForm-1-form-email">E-mail*</label> <span
                                            id="frm-tryitForm-1-form-email_message"></span></div>


                                    <div className="form-group">
                                        <input type="tel" name="phone-try" id="frm-tryitForm-1-form-phone" ref={ phoneRef }
                                            data-nette-rules="[{&quot;op&quot;:&quot;optional&quot;},{&quot;op&quot;:&quot;\\Ant\\Forms\\Validator::validateMobile&quot;,&quot;msg&quot;:&quot;Vyplňte prosím telefonní číslo v platném formátu (např. +420 774 828 242).&quot;}]"
                                            className="form-control has-js-label" data-lfv-initialized="true"
                                            data-lfv-message-id="frm-tryitForm-1-form-phone_message" data-placeholder="Telefon"
                                            placeholder="" data-js-label="true" /><label className="js-label"
                                            htmlFor="frm-tryitForm-1-form-phone">Telefon</label> <span
                                            id="frm-tryitForm-1-form-phone_message"></span></div>


                                    <div className="col-sm-12 condition">
                                        <div className="form-group">
                                            <label className="fw-normal fs-sm text-graylight">
                                                <label htmlFor="frm-tryitForm-1-form-conditions"><input type="checkbox" ref={ checkRef }
                                                        name="conditions" id="frm-tryitForm-1-form-conditions" required=""
                                                        data-nette-rules="[{&quot;op&quot;:&quot;:filled&quot;,&quot;msg&quot;:&quot;<span>Souhlasím se <a href=\&quot;zpracovani-osobnich-udaju\&quot; target=\&quot;_blank\&quot; className=\&quot;hlp-click\&quot; data-target=\&quot;.gdprUniversalHint\&quot;>zpracováním osobních údajů</a>.</span><div className=\&quot;hide noPrint gdprUniversalHint\&quot;>Vyplněné osobní údaje budeme zpracovávat za účelem zpracování vaší poptávky nebo dotazu. Díky tomu vám budeme moci co nejdříve odpovědět a vyřešit váš požadavek. Pro více informací <a href=\&quot;zpracovani-osobnich-udaju\&quot; target=\&quot;_blank\&quot;>klikněte zde</a>.</div> je povinná položka.&quot;}]"
                                                        data-lfv-initialized="true"
                                                        data-lfv-message-id="frm-tryitForm-1-form-conditions_message" /><span>Souhlasím
                                                        se <a href="https://www.finio.cz/zpracovani-osobnich-udaju"
                                                            target="_blank" className="hlp-click"
                                                            rel="undefined noopener noreferrer" data-hasqtip="1">zpracováním
                                                            osobních údajů</a>.</span>
                                                    <div className="hide noPrint gdprUniversalHint">Vyplněné osobní údaje budeme
                                                        zpracovávat za účelem zpracování vaší poptávky nebo dotazu. Díky tomu
                                                        vám budeme moci co nejdříve odpovědět a vyřešit váš požadavek. Pro více
                                                        informací <a href="https://www.finio.cz/zpracovani-osobnich-udaju"
                                                            target="_blank" rel="undefined noopener noreferrer">klikněte
                                                            zde</a>.</div>
                                                </label> </label>
                                            <span id="frm-tryitForm-1-form-conditions_message"></span></div>
                                    </div>



                                    <div className="alignC">
                                        <button type="button" data-loading-state="Zpracovávám..."
                                            className="btn btn-primary" onClick={ () => handleSendForm()}>Odeslat</button>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TryUs